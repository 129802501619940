/* Utils scss */

/* Imports */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'); */

@font-face {
	font-family: 'PlatinMTPro';
	src: url('../fonts/PlatinMTProLight.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap');

/* Colors */
$color-main: #fff;
$color-accent: #000;
$color-gray: #F3EBE5;
$color-blue: #0E253E;
$color-green: #708764;
$color-yellow: #FFD000;

/* Fonts and Sizes */
$huge: 4rem;
$large: 3rem;
$big: 2rem;
$extra: 1.5rem;
$medium-plus: 1.25rem;
$medium: 1rem;
$small: 0.75rem;
$tiny: 0.5rem;

/* Standard Tags */
h1, h2, h3, h4, h5, h6, p {
    font-weight: 400;
    margin: 0;
}

/* Boilerplate */
* {
	/* font-family: "PlatingMTPro"; */
    font-family: "Afacad", sans-serif;
	font-optical-sizing: auto;
	font-weight: 300;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	font-size: 16px;
	line-height: 1;
}

body {
    font-weight: 400;
    font-size: 100%;
    margin: 0;
    padding: 0;
    max-width: 100vw;
}

/* BG styles */
.bg-img-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-img-contain {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

/* Project elements */
.section-wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

/* Utility Mixins */
@mixin font-size($size) {
    font-size: $size;
    line-height: 1.5;
}

/* Flex classes */
.flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-space-between-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-space-around-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.flex-space-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex-space-evenly-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-start-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-end-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.flex-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.flex-center-start-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.flex-center-end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.flex-center-end-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.flex-start-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-start-start-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-start-end {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.flex-start-end-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.flex-end-start {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.flex-end-start-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}



/* Grid classes */
.grid {
    display: grid;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
}

.grid-cols-5 {
    grid-template-columns: repeat(5, 1fr);
}

.grid-gap-tny {
    gap: 0.5rem;
}

.grid-gap-sm {
    gap: 0.75rem;
}

.grid-gap-md {
    gap: 1rem;
}

.grid-gap-bg {
    gap: 1.5rem;
}

.grid-gap-lrg {
    gap: 2rem;
}


/* Video rules */
video::-webkit-media-controls-start-playback-button,
video::-webkit-media-controls,
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-overlay-play-button,
video::-webkit-media-controls-panel,
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-seek-back-button,
video::-webkit-media-controls-seek-forward-button,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-rewind-button,
video::-webkit-media-controls-return-to-realtime-button,
video::-webkit-media-controls-toggle-closed-captions-button,
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-play-pause-button {
    display: none !important;
    -webkit-appearance: none;
}