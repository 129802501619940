/* Utils scss */
/* Imports */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap");
@font-face {
  font-family: "PlatinMTPro";
  src: url("../fonts/PlatinMTProLight.ttf") format("truetype");
}
/* Colors */
/* Fonts and Sizes */
/* Standard Tags */
h1, h2, h3, h4, h5, h6, p {
  font-weight: 400;
  margin: 0;
}

/* Boilerplate */
* {
  /* font-family: "PlatingMTPro"; */
  font-family: "Afacad", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 16px;
  line-height: 1;
}

body {
  font-weight: 400;
  font-size: 100%;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

/* BG styles */
.bg-img-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-img-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* Project elements */
.section-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* Utility Mixins */
/* Flex classes */
.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-space-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-space-around-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-space-evenly-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-start-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-end-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-center-start-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flex-center-end-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-start-start-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-start-end-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-end-start-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

/* Grid classes */
.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-cols-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-gap-tny {
  gap: 0.5rem;
}

.grid-gap-sm {
  gap: 0.75rem;
}

.grid-gap-md {
  gap: 1rem;
}

.grid-gap-bg {
  gap: 1.5rem;
}

.grid-gap-lrg {
  gap: 2rem;
}

/* Video rules */
video::-webkit-media-controls-start-playback-button,
video::-webkit-media-controls,
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-overlay-play-button,
video::-webkit-media-controls-panel,
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-seek-back-button,
video::-webkit-media-controls-seek-forward-button,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-rewind-button,
video::-webkit-media-controls-return-to-realtime-button,
video::-webkit-media-controls-toggle-closed-captions-button,
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-play-pause-button {
  display: none !important;
  -webkit-appearance: none;
}
@charset "UTF-8";
/* Utils scss */
/* Imports */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap");
@font-face {
  font-family: "PlatinMTPro";
  src: url("../fonts/PlatinMTProLight.ttf") format("truetype");
}
/* Colors */
/* Fonts and Sizes */
/* Standard Tags */
h1, h2, h3, h4, h5, h6, p {
  font-weight: 400;
  margin: 0;
}

/* Boilerplate */
* {
  /* font-family: "PlatingMTPro"; */
  font-family: "Afacad", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 16px;
  line-height: 1;
}

body {
  font-weight: 400;
  font-size: 100%;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

/* BG styles */
.bg-img-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-img-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* Project elements */
.section-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* Utility Mixins */
/* Flex classes */
.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-space-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-space-around-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-space-evenly-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-start-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-end-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-center-start-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flex-center-end-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-start-start-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-start-end-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-end-start-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

/* Grid classes */
.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-cols-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-gap-tny {
  gap: 0.5rem;
}

.grid-gap-sm {
  gap: 0.75rem;
}

.grid-gap-md {
  gap: 1rem;
}

.grid-gap-bg {
  gap: 1.5rem;
}

.grid-gap-lrg {
  gap: 2rem;
}

/* Video rules */
video::-webkit-media-controls-start-playback-button,
video::-webkit-media-controls,
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-overlay-play-button,
video::-webkit-media-controls-panel,
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-seek-back-button,
video::-webkit-media-controls-seek-forward-button,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-rewind-button,
video::-webkit-media-controls-return-to-realtime-button,
video::-webkit-media-controls-toggle-closed-captions-button,
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-play-pause-button {
  display: none !important;
  -webkit-appearance: none;
}

/* ----------BODY STYLES--------- */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevenir desbordamiento horizontal */
}

/* ----------HEADER/NAV STYLES--------- */
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.fixed-header.scrolled {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
}

#nav-content {
  background-color: transparent;
  color: #fff;
  padding: 1rem 2rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.logo-container {
  flex-grow: 1;
}

.logo {
  width: 30%;
  height: auto;
}

.nav-links {
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.link {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
}

.hamburger {
  display: none;
}

/* ----------INDEXSECTION STYLES--------- */
.index-sec {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  /*     gap: 3rem; */
  background-color: black;
  color: #fff;
  overflow: hidden;
}

.left-content, .right-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.tall-img {
  width: 100%;
  height: auto;
}

.short-img {
  width: 100%;
  height: auto;
}

.index-text {
  justify-self: center;
  grid-column: span 2;
  z-index: 10;
  width: 80%;
  padding: 2rem;
}

.index-description {
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
}

.index-description2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  padding-top: 0.4rem;
}

.video-vimeo {
  padding: 2rem 0rem;
  background-color: black;
}

.fire {
  background-color: black;
  color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 3rem 0rem;
}

.vertical-line {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 1px;
  height: 90%;
  transform: translateX(-50%);
  background-color: #fff;
}

.left-text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  width: 60%;
}

.fire-title {
  font-size: 1rem;
  line-height: 1.2;
  padding-bottom: 0.4rem;
  font-weight: 600;
}

.fire-description {
  font-size: 0.9rem;
  line-height: 1.4;
}

.black-bg {
  background-color: black;
  z-index: 10;
  padding: 1rem;
}

.right-video video {
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
}

.black-space {
  background-color: black;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 3rem;
}

.black-space p {
  font-size: 1rem;
  line-height: 1.2;
  width: 60%;
}

/* ----------PAELLA STYLES--------- */
.paella {
  position: relative;
  background-color: black;
  color: #fff;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto auto;
  padding: 1rem 0rem;
}

/* .paella-img{
    grid-column: 2;
} */
.paella-img img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

/* .vertical-text {
    position: absolute;
    top: 50%;
    left: 0;
    transform-origin: top;
    transform: translateY(-50%) rotate(90deg);
    -webkit-transform: translateY(-50%) rotate(90deg);
    -moz-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    -o-transform: translateY(-50%) rotate(90deg);
} */
.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: left;
  -webkit-text-orientation: left;
  -moz-text-orientation: left;
  -o-text-orientation: left;
  -ms-writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.vertical-text h1 {
  font-size: 10rem;
  font-weight: 500;
  letter-spacing: 5rem;
  padding-top: 3rem;
  font-family: "Roboto Condensed", serif;
}

#dish-video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

#movil-video {
  display: none;
}

/* ----------DISH STYLES--------- */
.dish-description {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  padding: 1rem 2rem;
}

.dish-title h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  padding: 0.5rem;
}

.dish-text {
  padding: 0.5rem;
  width: 80%;
}

.dish-text h5 {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  padding-bottom: 1rem;
  text-align: center;
}

.dish-images {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  overflow: hidden;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

/* .dish-images img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;
} */
.dish-img-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem;
  align-items: stretch;
}

#product-carousel {
  grid-column: 1;
  height: 100%;
  max-width: 100%;
  display: grid;
}

#product-carousel img {
  grid-area: 1/1; /* Todas las imágenes se superponen */
  align-self: center;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

#product-carousel img.active {
  opacity: 1;
}

.chuleta video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.dish-description2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #000;
  padding: 1rem 2rem;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.wines {
  position: relative;
  box-sizing: border-box;
  margin-left: 5rem;
}

.wines h2 {
  padding: 1rem;
  transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
}

.wines h2 a {
  text-decoration: none;
  color: #000;
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: 1.2rem;
}

.wines img {
  width: 5%;
  position: absolute;
  bottom: -50%;
  left: 80%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.dish-text2 {
  display: flex;
  justify-content: flex-start;
  width: 80%;
}

.dish-text2 h5 {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.4;
}

/* ----------ABOUTUS STYLES--------- */
.about-us {
  padding-top: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-img img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

.about-description {
  text-align: center;
  padding: 2rem 2rem;
  width: 80%;
}

.about-description p {
  font-size: 1rem;
  line-height: 1.5;
}

.black-aboutus {
  background-color: black;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0rem;
  padding-bottom: 2rem;
  gap: 1rem;
}

.direction {
  width: 40%;
}

.direction h2 {
  font-size: 3rem;
  font-weight: 500;
  padding: 1rem 2rem;
  line-height: 1.5;
}

.aboutus-img img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}

/* ----------FOOTER STYLES--------- */
.black-footer {
  background-color: black;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 4rem;
  gap: 2rem;
}

.up-footer {
  border-top: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 3rem;
}

.legal {
  font-size: 1rem;
  line-height: 1.2;
}

.social {
  font-size: 1rem;
  line-height: 1.2;
}

.down-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
  padding: 3rem;
  border-bottom: 1px solid #fff;
}

.nav-links-footer {
  flex-grow: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}

.link-footer {
  text-decoration: none;
  color: #fff;
  font-size: 0.8rem;
}

.legal {
  list-style-type: none;
  text-decoration: none;
  color: #fff;
}

.redes {
  list-style-type: none;
  text-decoration: none;
  color: #fff;
}

/* ----------CARTA STYLES--------- */
.carta-sec {
  background-color: black;
  color: #fff;
  padding: 6rem 2rem;
}

.carta-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem;
  padding: 2rem 0rem;
  align-items: stretch;
}

.carta-item-img {
  grid-column: 1;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 80%;
}

.carta-item-img img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  object-position: top; /* Ensure the top part of the image is visible */
}

.carta-title {
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
}

.carta-title1 {
  font-size: 1.8rem;
  font-weight: 600;
  padding-bottom: 2rem;
  text-align: left;
  font-style: italic;
}

.nombre-plato {
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 0.3rem;
}

.cat-plato {
  font-size: 0.9rem;
  font-weight: 600;
  padding-bottom: 0.3rem;
  font-style: italic;
}

.eng-plato {
  font-size: 0.9rem;
  font-weight: 300;
  padding-bottom: 1rem;
  font-style: italic;
}

#carousel-carta {
  grid-column: 1;
  height: 100%;
  max-width: 100%;
  display: grid;
}

#carousel-carta img {
  grid-area: 1/1; /* Todas las imágenes se superponen */
  width: auto;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

#carousel-carta img.active {
  opacity: 1;
}

.carta-item-text {
  grid-column: 2; /* El texto ocupa la segunda columna */
  overflow-y: auto;
  height: 100%; /* Asegura que el texto ocupe toda la altura disponible */
  overflow: hidden; /* Evita que el contenido sobresalga */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* El texto se alinea desde arriba */
}

/* ---------- RESERVAS STYLES --------- */
.reservas-sec {
  background-color: black;
  color: #fff;
}

.reservation {
  padding-top: 6rem;
}

/* ----------FLOATING BUTTON STYLES--------- */
.floating-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: rotate(-90deg);
  transform-origin: right bottom;
  background-color: #FBD643;
  padding: 0.8rem 2rem;
  z-index: 1000;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  transition: background-color 0.3s ease;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  -o-border-radius: 10px 10px 0 0;
}

.floating-button:hover {
  background-color: rgb(179, 151, 29);
}

.reserv {
  text-decoration: none;
  color: black;
}

/* ----------MEDIA QUERIES--------- */
@media (max-width: 1024px) {
  .vertical-text h1 {
    font-size: 7rem;
    letter-spacing: 4rem;
    padding-top: 3rem;
  }
  .direction {
    width: 50%;
  }
  .direction h2 {
    font-size: 2rem;
    padding: 1rem 2rem;
    line-height: 1.4;
  }
  .carta-item-img {
    max-height: 100%;
  }
  .dish-text2 h5 {
    width: 70%;
  }
  .wines {
    margin-left: 2rem;
  }
  .about-us {
    padding-top: 7rem;
  }
  .fire {
    gap: 0;
  }
}
@media (max-width: 768px) {
  .fire {
    gap: 0rem;
    padding: 2rem 0rem;
  }
  .right-video {
    display: flex;
    justify-content: flex-end;
    width: 80%;
  }
  .black-space {
    padding: 2rem;
  }
  .black-space p {
    width: 60%;
  }
  .paella {
    padding: 0rem 0rem;
    gap: 0;
  }
  .vertical-text h1 {
    font-size: 5rem;
    letter-spacing: 3rem;
    padding-top: 0rem;
  }
  .dish-title h3 {
    font-size: 1.2rem;
  }
  .dish-images {
    padding-bottom: 1rem;
  }
  .wines h2 {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  .wines h2 a {
    font-size: 2.5rem;
    letter-spacing: 1rem;
  }
  .black-aboutus {
    padding: 2rem 0rem;
  }
  .direction h2 {
    font-size: 1.8em;
    padding: 1rem 1.5rem;
  }
  .about-us {
    padding-top: 2rem;
  }
  .hamburger {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 101;
    transition: color 0.3s ease;
  }
  #menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.95);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    transition: transform 0.3s ease;
    z-index: 100;
    overflow-y: auto;
  }
  #menu.open {
    display: flex;
  }
  body.no-scroll {
    overflow: hidden;
  }
  .nav-links a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  .carta-content {
    display: flex;
    flex-direction: column;
  }
  .carta-item-text {
    text-align: center;
  }
}
@media (max-width: 550px) {
  .index-sec {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 0;
    padding-bottom: 0rem;
  }
  .index-description {
    font-size: 0.9rem;
  }
  .short-img {
    display: none;
  }
  .right-content {
    justify-content: flex-end;
  }
  .black-bg {
    padding: 0rem 1rem;
    text-align: center;
  }
  .video-vimeo {
    padding: 1rem 0rem;
  }
  .fire {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0rem;
  }
  .left-text {
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }
  /* .vertical-line {
      display: none;
  } */
  .right-video {
    width: 100%;
    height: auto;
  }
  .black-space {
    justify-content: center;
    padding: 2rem 3rem;
  }
  .black-space p {
    width: 100%;
    line-height: 1.4;
    font-size: 0.8rem;
    text-align: center;
  }
  .paella {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0rem;
  }
  .vertical-text {
    display: none;
  }
  .vertical-text h1 {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
  }
  .dish-img-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  #product-carousel img {
    justify-self: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
  #dish-video {
    display: none;
  }
  #movil-video {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
  .dish-description {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
  }
  .dish-text {
    padding: 0;
    width: 90%;
  }
  .dish-text h5 {
    font-size: 0.8rem;
    padding-bottom: 0.8rem;
    line-height: 1.4;
  }
  .dish-images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    padding-left: 0;
    padding-bottom: 0;
  }
  .dish-description2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
  }
  .wines {
    order: 2;
    padding-top: 3rem;
    margin-left: 0rem;
  }
  .wines h2 a {
    font-size: 1.5rem;
    letter-spacing: 0.6rem;
  }
  .dish-text2 {
    order: 1;
    width: 100%;
    padding: 0%;
  }
  .dish-text2 h5 {
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.4;
    padding: 1rem 2rem;
  }
  .about-description {
    padding: 2rem;
  }
  .about-description h5 {
    font-size: 1rem;
    line-height: 1.2;
    padding-bottom: 0.5rem;
  }
  .black-aboutus {
    padding: 0rem 0rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .direction {
    width: 90%;
    order: 2;
  }
  .direction h2 {
    font-size: 2.2rem;
    line-height: 1.2;
    padding: 1rem;
    text-align: center;
  }
  .aboutus-img {
    order: 1;
  }
  .about-description p {
    font-size: 0.8rem;
    line-height: 1.4;
  }
  .black-footer {
    padding: 1rem 2rem;
    gap: 1rem;
  }
  .up-footer {
    padding: 2rem;
    gap: 1rem;
  }
  .down-footer {
    padding: 1rem 1rem;
    gap: 2rem;
    flex-direction: column;
    border: none;
    justify-content: space-around;
  }
  .link-footer {
    font-size: 0.7rem;
  }
  .social a {
    font-size: 0.7rem;
  }
  .legal a {
    font-size: 0.7rem;
  }
  .nav-links-footer {
    justify-content: center;
  }
  .created-by {
    border-top: 1px solid #fff;
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
  .carta-title1 {
    text-align: center;
  }
}
@media (max-width: 375px) {
  .vertical-text {
    top: -50%;
  }
  .direction h2 {
    font-size: 1.8rem;
  }
  .about-description {
    padding: 1rem;
    text-align: left;
  }
  .dish-text2 h5 {
    padding: 1rem;
  }
  .dish-description {
    padding: 2rem 1rem;
  }
  .black-space {
    justify-content: center;
    padding: 2rem;
  }
  .paella {
    padding: 0rem 0rem;
  }
  .link-footer {
    font-size: 0.6rem;
  }
}